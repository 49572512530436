import { handleUnauthorizedException } from './index'
import { logger } from '../../logger'
import {
    ApiError,
    MarketplaceUpdateOrderRequest,
    OrderMarketplaceService,
} from '@lazr/openapi-client'
import { ServerErrorMessage, generateServerErrorMessage } from '@/app/ui-new/lib/helpers'
import { originPickupDateFormat } from '@/app/ui-new/pages/marketplace/helpers/marketplaceHelpers'

export const OrderMarketplaceApiService = Object.freeze({
    update: async(marketplaceStepName: string, orderAttributes: MarketplaceUpdateOrderRequest, validate: boolean): Promise<void> => {
        console.debug('OrderMarketplaceApiService > orderAttributes', orderAttributes)
        if (!orderAttributes.orderId) {
            logger.error('Order id is missing')
            throw new Error('Unable to edit order')
        }

        try {
            await OrderMarketplaceService.updateOrderMarketplace(marketplaceStepName, orderAttributes, validate)
        } catch (error: any) {
            handleUnauthorizedException(error)
            logger.debug('Order marketplace edit', JSON.stringify(error, null, 4))
            if (error instanceof ApiError) {
                const errorResponse: ServerErrorMessage = JSON.parse(JSON.stringify(error, null, 4))
                throw errorResponse
            }
            throw generateServerErrorMessage('Unable to update order marketplace')
        }
    },
})
